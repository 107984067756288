import React from 'react'
import QuoteForm from './QuoteForm'
import image from "../assets/building2.png"

const Pricing = () => {
  return (
    <div name="pricing" className='w-full h-fit text-white bg-slate-100 rounded-2xl'>
      <div className='sm:flex-row flex flex-col items-center justify-start max-w-6xl mx-auto h-full px-3'>
        
        <div className='px-4 py-12 flex flex-col'>
            <h2 className='text-3xl sm:text-5xl font-bold text-blue'>Apply Now</h2>
            
            <p className='text-lg sm:text-xl py-8 text-gray-500'>Join the team here at Shivaay Planning Now.</p>
            <img src={image} alt='building' className='rounded-xl'></img>
        </div>

        <div className='w-full ml-auto'>
                <QuoteForm />
                
        </div>
        
            
      </div>
    </div>
  )
}

export default Pricing
