import React from 'react'
import GreenBtn from '../components/GreenBtn'
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/get-a-quote`;
        navigate(path);

    }
    return (

        <div name="quote" className='w-full h-screen bg-testColor'>
            <div className="flex flex-col max-w-screen-xl mx-auto h-full px-3 py-12">
                <div className='mt-28'>
                    <div>
                        <h1 className='text-4xl font-bold text-lightColor  w-full'>Sorry, <span className='text-blue'>we couldn't find the page you were looking for ;(</span></h1>
                        <p className='text-sm mt-2'>{new Date().toLocaleString() + ""} </p>

                    </div>

                </div>

                <GreenBtn title="Get a Quote Now" className="p-3 mt-5 max-w-sm" onClick={routeChange} />





            </div>
        </div>
    )
}
