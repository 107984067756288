import GreenBtn from './GreenBtn'
import { useNavigate } from 'react-router-dom'
import LatestWork from './LatestWork'
import penpaper from '../assets/herovideo1.mp4'
import { motion } from 'framer-motion';




const Hero = () => {

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/get-a-quote`;
    navigate(path);

  }




  return (
    <div name="home" className='flex flex-col justify-between w-full lg:h-fit sm:h-auto bg-testColor md:text-left '>
      <div className='max-w-screen-2xl px-5 mt-32 mb-20  mx-auto rounded-3xl p-10 bg-slate-100 ' >
        <div className='grid md:grid-cols-3 m-auto items-center justify-center'>
          <motion.div className='flex flex-col justify-center md:items-start w-full px-2 py-2 sm:px-20 sm:py-8 h-full col-span-2 text-center md:text-start'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <motion.h1 className='pt-1  pb-3 text-4xl sm:text-5xl md:text-6xl font-bold text-gray-900 '
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <span className='text-lightColor'>Shivaay Planning</span> <span className='text-blue'>Services</span>
            </motion.h1>
            <motion.h2 className='text-2xl sm:text-2xl font-semibold text-blue'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              Providing Architectural Services Since 2016
            </motion.h2>
            <motion.h3 className='text-xl sm:text-xl pt-4'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              Site Visit and Survey for <span className='font-bold text-blue'>FREE</span> ✅
            </motion.h3>
            <motion.h3 className='text-xl sm:text-xl'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.7 }}
            >
              Planning resubmission for <span className='font-bold text-blue'>FREE</span> ✅
            </motion.h3>
            <motion.h3 className='text-xl sm:text-xl mb-8'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.8 }}
            >
              Guranteed<span className='font-bold text-blue'> Approvals</span> ✅
            </motion.h3>
            <motion.div className='flex flex-row w-full justify-center md:justify-start'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.9 }}
            >
              <a href="/how-we-work" className='hover:animate-pulse'>
                <h4 className='text-xl mb-8'>
                  <span className='text-blue'>→ Read about</span> how we work
                </h4>
              </a>
            </motion.div>
            <motion.div className='flex flex-row w-full justify-center md:justify-start'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 1 }}
            >
              <GreenBtn
                title="Get a Quote now →"
                className="text-lg capitalize py-3 px-6 lg:w-8/12 sm:w-8/12 mb-16 "
                onClick={routeChange}
              />
            </motion.div>
          </motion.div>

          <motion.div className='w-full items-center justify-center ml-0 md:-ml-16 min-w-min overflow-hidden col-span-2 md:col-span-1'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1.1 }}
          >
            <video className="w-full rounded-3xl" controls={false} autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true">
              <source src={penpaper} type="video/mp4" />
            </video>
          </motion.div>

        </div>

        <motion.div className='w-full col-span-3 my-5 hidden sm:hidden lg:grid md:hidden'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <LatestWork />
        </motion.div>
      </div>
    </div>
  )
}

export default Hero
