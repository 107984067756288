import React, { useEffect, useState } from 'react'
import BlueBtn from './BlueBtn'
import { useNavigate } from "react-router-dom";

const ReviewTest = () => {

    const pics = [
        {
            id: 1,
            name: "Jaspreet Singh - Hounslow",
            number: "01/04/2023",
            info: "Just finished my loft conversion, and the plans from Shivaay Planning were just phenomenal! They were quick to provide them and so thorough with their work.",
        },
        {
            id: 2,
            name: "Sukhwinder Singh - Southall",
            number: "29/05/2023",
            info: "Fast, affordable and professional – that's shivaay planning for you. Their extension plans are top-notch!",
        },
        {
            id: 3,
            name: "Dilpreet Singh - Pinner",
            number: "06/06/2023",
            info: "Faultless from start to finish. The team were always prompt and very helpful when replying to any questions, highly recommend!",
        },
        {
            id: 4,
            name: "Joseph - Islington",
            number: "03/07/2023",
            info: "Absolute 5 star experience with this company! From start to finish was so efficient with responses and made to whole process beyond easy!",
        },
        {
            id: 5,
            name: "Hasim - East London",
            number: "23/07/2023",
            info: "Shivaay Planning sets the bar high for speed, cost-effectiveness, and professionalism. The way they execute their work is truly remarkable!",
        },
        {
            id: 6,
            name: "Andrew - Chelsea",
            number: "11/08/2023",
            info: "I was blown away by the efficiency, affordability, and professionalism of Shivaay Planning.",
        },
    ]

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/get-a-quote`;
        navigate(path);
    }

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide(prevSlide => (prevSlide + 1) % pics.length);
        }, 6000); // Change slide every 6 seconds
    
        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [pics.length]); // Add pics.length to the dependency array
    


    return (
        <div name="testimonals" className='w-full h-fit bg-lightColor'>
            <div className='flex flex-col items-center justify-center max-w-screen-xl mx-auto h-full px-3 py-3'>
                <h2 className='text-4xl font-bold pt-6'>Testimonials</h2>
                <p className='text-lg my-4 text-center'>Take a look at what our customers have to say!</p>
                <div className='px-10 pt-3 sm:pt-4 text-black justify-center items-center mx-auto max-w-screen-md p-5 mt-5 rounded-xl bg-sky-100 '>
                    <h3 className='text-xl font-bold mt-5'>{pics[currentSlide].name}</h3>
                    <p className="text-lg mt-5">{pics[currentSlide].info}</p>
                    <p className="text-lg font-bold mb-2 text-right mt-5">{pics[currentSlide].number}</p>
                    


                </div>
                
                <BlueBtn title="Get a Quote Now" className="px-6 py-4 m-10" onClick={routeChange} />
            </div>
        </div>
    )
}

export default ReviewTest
