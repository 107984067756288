import React from 'react'
import BlueBtn from './BlueBtn'
import { useNavigate } from "react-router-dom";

const ExperienceWidget = () => {

    const pics = [
        {
            id: 1,
            number: "7",
            info: "Years of Experience",
        },
        {
            id: 2,
            number: "99.9%",
            info: "Rate of Approvals",
        },
        {
            id: 3,
            number: "100+",
            info: "Satisfied Customers",
        },
        {
            id: 4,
            number: "557",
            info: "Completed Projects",
        },
    ]

    let navigate = useNavigate();
    const routeChange = () =>{ 
        let path = `/join-us`; 
        navigate(path);
      }

  return (
    <div name="testimonals" className='w-full h-fit bg-lightColor'>
      <div className='flex flex-col items-center justify-center max-w-screen-xl mx-auto h-full px-3 py-3'>
        <h2 className='text-4xl font-bold pt-6'>Join Us</h2>
        <p className='text-lg my-4 px-5 sm:px-20 text-center'>Our team includes world-class talent in engineering, product design, operations, customer service, sales and marketing. We are always looking for talented, driven individuals to join the team at Shivaay Planning.</p>
        <div className='grid lg:grid-cols-4 gap-x-12 gap-y-16 px-4 pt-3 sm:pt-10 text-black'>
            {pics.map(({id, number, info}) => (
            <div key={id} className=' rounded-lg'>
                <div className=''>
                    <h3 className='text-4xl font-bold text-center'>{number}</h3>
                    <h4 className='text-lg'>{info}</h4>                        
                </div>
            </div>
            ))}
            
        </div>
        <BlueBtn title="Join Us Now" className="px-6 py-4 m-10" onClick={routeChange}/>
      </div>
    </div>
  )
}

export default ExperienceWidget
