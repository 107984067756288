import React from 'react'

export default function Quote() {
  return (
    <div name="quote" className='w-full md:h-fit bg-lightColor'>
        <div className='flex mx-auto flex-col items-center justify-center max-w-screen-xl h-full px-3 py-12'>
            <h2 className='text-2xl font-bold text-center sm:text-4xl'>“Architecture should speak of its time and place, but yearn for timelessness.”</h2>
        </div>
    </div>
  )
}
