import React from "react";

import { FaFacebook, FaInstagramSquare, FaTwitter, FaWhatsapp, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Footer = () => {

  const links = [
    {
      id: 0,
      link: '',
      title: "Home",
    },
    {
      id: 1,
      link: 'about-us',
      title: "About Us",
    },
    {
      id: 2,
      link: 'how-we-work',
      title: "How we work",
    },
    {
      id: 3,
      link: 'portfolio',
      title: "Portfolio",
    },
    {
      id: 4,
      link: 'services',
      title: "Services",
    },
    {
      id: 5,
      link: 'get-a-quote',
      title: "Get a quote",
    },
    {
      id: 6,
      link: 'join-us',
      title: "Join Us",
    },

  ]
  const location = useLocation();

  return (
    <div className="w-full bg-slate-900 text-gray-400 py-8 px-2">
      <div className="flex flex-col items-center justify-center max-w-screen-xl mx-auto h-full px-3 py-12">
        <div className="flex flex-col  items-center justify-center w-full">
          <div className="w-3/4 md:w-full">
            <div className="w-full pt-4 md:pt-2 text-center">
              
              <ul className='flex md:hidden  flex-col mb-5'>

                {
                  links.map(({ id, link, title }) => (
                    <li key={id} className={`capitalize py-2 cursor-pointer duration-200 ${location.pathname === "/" + link ? "text-lightColor" : "hover:text-lightColor"}`}><a href={"/" + link}>{title}</a></li>
                  ))
                }
              </ul>
              <h3 className="text-xl uppercase text-white font-bold py-10">Connect With Us</h3>
              <ul className='p-5 mb-5 text-slate-100 flex flex-row gap-8 justify-center rounded-xl'>
                <a href="https://www.facebook.com/UPCgroup2022"><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaFacebook size={35}></FaFacebook></li></a>
                <a href="https://wa.me/07410377527"><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaTwitter size={35}></FaTwitter></li></a>
                <a href="https://wa.me/07410377527"><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaWhatsapp size={35}></FaWhatsapp></li></a>
                <a href="https://instagram.com/shivaayplanningservices?igshid=MzRlODBiNWFlZA=="><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaInstagramSquare size={35}></FaInstagramSquare></li></a>
                <a href="mailto:info@shivaayplanning.com"><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaEnvelope size={35}></FaEnvelope></li></a>
              </ul>
              
              
              <p className="font-bold text-lightColor text-lg" >Phone: +44 7410377527</p>
              <a className="font-bold mb-8 text-lightColor text-lg" href="mailto:info@shivaayplanning.com" >Email: info@shivaayplanning.com</a>
              <h4 className="mt-6 mb-10">© Shivaay Planning Services Ltd 2023 | Company Reg: 14712998 <br /> All rights reserved.<br /></h4>
              <div className="flex flex-wrap gap-2 mx-auto justify-center">
              <a href="https://mayurhingaladiya.com/" target="_blank" rel="noreferrer" className="font-mono">Created by: <span className="text-blue font-bold">Mayur Hingaladiya</span></a>
              <a href="https://www.linkedin.com/in/mayurhingaladiya/" rel="noreferrer" target="_blank"><FaLinkedin size={29} className="-mt-1"></FaLinkedin></a>
              <a href="https://wa.me/07497578831" target="_blank" rel="noreferrer"><FaWhatsapp size={29} className="-mt-1"></FaWhatsapp></a>

              </div>
              
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Footer;