import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";

import SocialMedia from "./components/SocialMedia";
import AnimatedRoutes from "./AnimatedRoutes";


function App() {

  return (
    <div>
      <SocialMedia />

      <Router>
        <Navbar />
        <AnimatedRoutes />
        <Footer />
      </Router>

    </div>
  )
}

export default App;
