import React from 'react'
import { FaEnvelope, FaFacebook, FaInstagramSquare, FaTwitter, FaWhatsapp } from "react-icons/fa";

export default function SocialMedia() {
    return (
        <div className='fixed  h-full flex-col justify-center z-30'>


            <div className='hidden 2xl:absolute 2xl:flex w-fit h-full flex-col justify-center'>

                <ul className='p-5 text-blue bg-white rounded-xl'>
                    <a href="https://www.facebook.com/UPCgroup2022"><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaFacebook size={35}></FaFacebook></li></a>
                    <a href="/"><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaTwitter size={35}></FaTwitter></li></a>
                    <a href="https://wa.me/07410377527"><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaWhatsapp size={35}></FaWhatsapp></li></a>
                    <a href="https://instagram.com/shivaayplanningservices?igshid=MzRlODBiNWFlZA=="><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaInstagramSquare size={35}></FaInstagramSquare></li></a>
                    <a href="mailto:info@shivaayplanning.com"><li className='my-2 hover:scale-105 hover:shadow-xl duration-200 ease-in'><FaEnvelope size={35}></FaEnvelope></li></a>
                </ul>
            </div>
        </div>
    )
}
