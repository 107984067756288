import React from 'react'
import Main from "./pages/Main";
import Portfolio from "./pages/Portfolio";
import ServicesAndPrices from "./pages/ServicesAndPrices";
import QuotePage from "./pages/QuotePage";
import HowWeWork from "./pages/HowWeWork";
import AboutUs from "./pages/AboutUs";
import JoinUs from "./pages/JoinUs";
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import NotFound from './pages/NotFound';

export default function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
            <Route path="/" exact element={<Main />} />
            <Route path="/services" element={<ServicesAndPrices />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/get-a-quote" element={<QuotePage />} />
            <Route path="/how-we-work" element={<HowWeWork />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/join-us" element={<JoinUs />} />
            <Route path="/*" element={<NotFound/>} />
        </Routes>

        </AnimatePresence>
        
    )
}
