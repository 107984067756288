import React, { useEffect } from 'react'
import { FaChevronCircleRight } from 'react-icons/fa'
import GreenBtn from './GreenBtn'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';


const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const services = [
        {
            id: 1,
            title: "Site Survey",
            subTitle: "Amet no kasd tempor stet rebum no. Ipsum sit diam diam sanctus, stet ipsum sit.",
            planningprice: "£incl",
            buildingreg: "£incl",
        },
        {
            id: 2,
            title: "Lease Map",
            subTitle: "Schematic Floorplans - An essential communication tool for prospective buyers.",
            planningprice: "£95.00",
            buildingreg: "N/A",
        },
        {
            id: 3,
            title: "Porch Extension",
            subTitle: "Dolor rebum dolor dolore takimata rebum invidunt elitr kasd et sit. Lorem vero consetetur dolore diam nonumy elitr lorem magna,.",
            planningprice: "£595.00",
            buildingreg: "£345.00",
        },
        {
            id: 4,
            title: "Drop Kerb/Hard Standing",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "£595.00",
            buildingreg: "N/A",
        },
        {
            id: 5,
            title: "Garage Conversion",
            subTitle: "Dolor rebum dolor dolore takimata rebum invidunt elitr kasd et sit. Lorem vero consetetur dolore diam nonumy elitr lorem magna,.",
            planningprice: "£695.00",
            buildingreg: "£395.00",
        },
        {
            id: 6,
            title: "Planning Application for maximising your house footprint",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "£595.00",
            buildingreg: "£495.00",
        },
        {
            id: 7,
            title: "Single Storey Extension (Side / Rear)",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "£695.00",
            buildingreg: "£495.00",
        },
        {
            id: 8,
            title: "Double Storey Extension (Side / Rear)",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "£895.00",
            buildingreg: "£645.00",
        },
        {
            id: 9,
            title: "Loft Conversion (L-Shape Dormer)",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "£795.00",
            buildingreg: "£595.00",
        },
        {
            id: 10,
            title: "Flat Conversion",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "Call Us",
            buildingreg: "Call Us",
        },
        {
            id: 11,
            title: "Electrical Plans",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "£795.00",
            buildingreg: "£595.00",
        },
        {
            id: 12,
            title: "Change of Use",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "Call Us",
            buildingreg: "Call Us",
        },

        {
            id: 14,
            title: "Interior Designing and 3D Views",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "Call Us",
            buildingreg: "Call Us",
        },
        {
            id: 15,
            title: "Lawful Certificates",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "Call Us",
            buildingreg: "Call Us",
        },
        {
            id: 16,
            title: "Condition Removals",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "Call Us",
            buildingreg: "Call Us",
        },
        {
            id: 17,
            title: "Window Additions",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "Call Us",
            buildingreg: "Call Us",
        },
        {
            id: 18,
            title: "Permission for Kitchen Extraction",
            subTitle: "Magna lorem sed accusam lorem accusam lorem amet amet stet. Diam elitr magna elitr elitr et at. Est et vero.",
            planningprice: "Call Us",
            buildingreg: "Call Us",
        },
    ]
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/get-a-quote`;
        navigate(path);
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} name="services" className='w-full h-full bg-testColor'>
            <div className='flex mx-auto flex-col items-start justify-center max-w-screen-xl px-3 py-12'>
                <motion.h1 className='text-3xl sm:text-5xl font-bold mt-24 text-blue'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6 }}
                >
                    Our Services
                </motion.h1>
                <motion.h2 className='text-lg sm:text-xl py-8 text-gray-600 text-start'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    Our prices for Planning Drawings, Building Regulation Plans, and Planning Applications are fixed use our FREE quote tool to enquire NOW!
                </motion.h2>
                <div className='grid grid-cols-1 md:grid-cols-3'>
                    <motion.div className='py-6 px-10 bg-slate-100 h-fit rounded-3xl mt-4'
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                    >
                        <motion.p className='text-3xl'
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6, delay: 0.5 }}
                        >
                            Use our <span className='font-bold text-lightColor'>FREE</span> Quote tool to get a quote <span className='font-bold text-blue'>NOW!</span>
                        </motion.p>
                        <motion.h2 className='my-10 md:my-20'
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6, delay: 0.6 }}
                        >
                            Our free quote tool ensures easy, quick access to our excellent team, dedicated to making your dream a reality.
                        </motion.h2>
                        <motion.div className='flex flex-row w-full my-5'
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.6, delay: 0.7 }}
                        >
                            <GreenBtn title="Get a Quote now →" className="text-lg capitalize py-3 px-6 lg:w-8/12 sm:w-8/12 mb-16 " onClick={routeChange}/>
                        </motion.div>
                    </motion.div>
                    <motion.div className='grid grid-cols-2 sm:grid-cols-3 gap-4 pt-4 col-span-2 px-10'
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.8 }}
                    >
                        {services.map(({ id, title, subTitle, planningprice, buildingreg, img }) => (
                            <motion.div key={id} onClick={routeChange} className='flex items-start p-4 bg-slate-100 text-black rounded-3xl hover:shadow-xl hover:bg-slate-50 duration-700 ease-in-out cursor-pointer'
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.8 }}
                            >
                                <div>
                                    <FaChevronCircleRight size={20} className='mt-3 mr-4 text-lightColor cursor-default' />
                                </div>
                                <div>
                                    <motion.h3 className='capitalize font-bold text-sm md:text-lg text-black pt-2 pb-4'
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.6, delay: 0.9 }}
                                    >
                                        {title}
                                    </motion.h3>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </motion.div>

    )
}

export default Services
