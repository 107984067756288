import React from 'react'
import Services from '../components/Services'
import Quote from '../components/Quote'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async'

export default function ServicesAndPrices() {
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} className=''>
      <Helmet>
          <title>Shivaay Planning | Services</title>
          <meta
            name="description"
            content="Elevating Spaces, Enriching Lives: Shivaay Planning Services Offerings. Immerse yourself in a world of architectural excellence and creativity."
          />
          <meta property="og:type" content="website" />
        <meta property="og:title" content="Shivaay Planning | Services" />
        <meta property="og:description" content="Elevating Spaces, Enriching Lives: Shivaay Planning Services Offerings. Immerse yourself in a world of architectural excellence and creativity." />
        <meta property="og:url" content="www.shivaayplanning.com" />
        <meta name="twitter:title" content="Shivaay Planning | Services" />
        <meta name="twitter:description" content="Elevating Spaces, Enriching Lives: Shivaay Planning Services Offerings. Immerse yourself in a world of architectural excellence and creativity." />
          <link rel="canonical" href='/services'/>
        </Helmet>
      <Services/>
      <Quote/>
    </motion.div>
  )
}
