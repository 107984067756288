import React from 'react'
import GreenBtn from './GreenBtn'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';



export default function WhyUs() {
    const details = [
        {
            id: 1,
            title: '99.9%',
            subtitle: 'Approvals',
        },
        {
            id: 2,
            title: '24/7',
            subtitle: 'Customer Service',
        },
        {
            id: 3,
            title: '5+ years',
            subtitle: 'Experience in the field',
        },
        {
            id: 4,
            title: '450+',
            subtitle: 'Reviews and Clients',
        },
    ]

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/get-a-quote`;
        navigate(path);
    }
    const [ref, inView] = useInView({ triggerOnce: true });


    return (
        <div name="about" className='w-full md:h-fit pb-32 bg-testColor text-black'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-10 items-center justify-center max-w-screen-2xl mx-auto h-full px-8 py-8 sm:px-24 sm:py-32 bg-slate-100 rounded-3xl '>
                <motion.div className='text-start w-fit' ref={ref}>
                    <motion.h2 className='text-2xl sm:text-4xl font-bold text-center sm:text-start'
                        initial={{ opacity: 0, y: 20 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.6, delay: 0.3 }}
                    >
                        Why choose <span className='text-lightColor'>Shivaay Planning</span><span className=''> Services?</span>
                    </motion.h2>
                    <motion.p className='text-md sm:text-lg font-light text-center sm:text-start py-8'
                        initial={{ opacity: 0, y: 20 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                    >
                        We understand that your home is more than just a structure; it is a reflection of your unique personality, aspirations, and lifestyle. With years of experience in the industry, we have honed our craft to perfection, combining the art of architectural design with the science of functionality. We plan and create spaces that are not only visually captivating but also seamlessly integrated, enhancing the way you live, work, and play.
                    </motion.p>
                    <a href="/services" className=''>
                        <motion.h3 className='text-xl mb-8 w-full text-center sm:text-start hover:animate-pulse'
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                            transition={{ duration: 0.6, delay: 0.5 }}
                        >
                            <span className='text-blue'>→ Services</span> we offer
                        </motion.h3>
                    </a>
                    <motion.div className='flex flex-row w-full justify-center sm:justify-start'
                        initial={{ opacity: 0, y: 20 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.6, delay: 0.6 }}
                    >
                        <GreenBtn title="Get a Quote now →" className="text-lg capitalize py-3 px-6 lg:w-8/12 sm:w-8/12 mb-16 " onClick={routeChange} />
                    </motion.div>
                </motion.div>
                <div className='grid md:grid-cols-2 gap-5 px-2 w-full'>

                    {details.map(({ id, title, subtitle }) => (
                        <motion.div key={id} className='flex items-center justify-center'
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                            transition={{ duration: 0.6, delay: 0.7 }}
                        >
                            <motion.div className='flex flex-col items-center justify-center w-full h-full py-10 sm:py-20 rounded-3xl bg-sky-100'
                                initial={{ opacity: 0, y: 20 }}
                                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                                transition={{ duration: 0.6, delay: 0.8 }}
                            >
                                <p className='text-3xl md:text-5xl sm:text-3xl font-bold text-blue'>{title}</p>
                                <p className='text-gray-700 mt-2 capitalize'>{subtitle}</p>
                            </motion.div>
                        </motion.div>
                    ))}

                </div>
            </div>
        </div>

    )
}
