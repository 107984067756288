import React from 'react'
import Pricing from '../components/Pricing'
import { Helmet } from 'react-helmet-async';

export default function JoinUs() {

  return (
    <div name="joinus" className='w-full h-fit bg-testColor'>
      <Helmet>
          <title>Shivaay Planning | Join Us</title>
          <meta
            name="description"
            content="Join the fabulous team at Shivaay Planning Services TODAY!"
          />
          <link rel="canonical" href='/join-us'/>
        </Helmet>
      <div className="flex flex-col justify-center items-start max-w-screen-xl mx-auto h-full px-3 py-12">
        <h1 className='text-3xl sm:text-5xl font-bold text-black mt-24 w-full'>Join Us</h1>
        <p className='text-lg py-5'>We undertake hundreds of projects each and every year. Our ever growing experience has enabled us to develop our service to
          focus on delivering what really matters - our approach works and has become very successful.</p>
      </div>
      <div className='w-fit mx-auto'>
        <Pricing/>
      </div>
      
    </div>
  )
}

