import React, { useEffect } from 'react'
import GreenBtn from './GreenBtn';

export default function ThankYou({ planningType, serviceType, formData, reference }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCall = () => {
        window.location.href = 'tel:07410377527'; // Replace with the actual phone number
      };


    return (
        <div name="quote" className='w-full h-fit'>
            <div className="flex flex-col justify-center  max-w-screen-xl mx-auto h-full px-3 py-12">
                <div>
                    <div>
                    <h1 className='text-4xl font-bold text-lightColor  w-full'>Thank you <span className='text-blue'>we aim to contact you within 24hours!</span></h1>
                <p className='text-sm mt-2'>Published on {new Date().toLocaleString() + ""} </p>
                    </div>

                </div>
                
                <p className='pt-8'>Name: {formData.name} <br /> Email: {formData.email} <br /> Phone: {formData.phone} <br /> Address: {formData.address}, {formData.postcode}</p>
                <p className='font-bold mt-5'>Quoted elements <br /> Planning: {planningType} <br /> Service: {serviceType}<br /></p>
                
                <GreenBtn title="Call Now" className="p-3 mt-5" onClick={handleCall}/>
                
                



            </div>
        </div>
    )
}
