import React, { useState } from 'react';
import {FaBars, FaTimes} from "react-icons/fa";

import GreenBtn from "./GreenBtn"
import logo from "../assets/logosv3.svg"
import { useLocation } from 'react-router-dom';


const Navbar = () => {
  const [navigation, setNavigation] = useState(false);

  const links = [
    {
      id: 0,
      link: '',
      title: "Home",
    },
    {
      id: 1,
      link: 'about-us',
      title: "About Us",
    },
    {
      id: 2,
      link: 'how-we-work',
      title: "How we work",
    },
    {
      id: 3,
      link: 'portfolio',
      title: "Portfolio",
    },
    {
      id: 4,
      link: 'services',
      title: "Services",
    },
    {
      id: 5,
      link: 'get-a-quote',
      title: "Get a quote",
    },
    

  ]

  const location = useLocation();
  const handleCall = () => {
    window.location.href = 'tel:07410377527'; // Replace with the actual phone number
  };


  return (
    <div className='w-screen h-20 z-20 fixed bg-testColor text-gray-900'>
      <div className='[&>*:nth-child(2)]:ml-auto justify-between px-3 flex items-center w-full h-full'>
        <div className='flex items-center overflow-hidden'>
          <a href='/' className=''><img src={logo} alt='shivaay planning services logo' className='h-[200px] sm:h-[230px] w-auto'></img></a>
        </div>
        <ul className='hidden lg:flex p-10 '>

          {
            links.map(({ id, link, title }) => (
              <li key={id} className={`capitalize p-4  duration-200 ${location.pathname === "/" + link ? "text-lightColor" : "hover:text-lightColor"}`}><a href={"/" + link}>{title}</a></li>
            ))
          }
        </ul>

        <div className='hidden lg:flex mr-4'>
          <GreenBtn title="Call Us" className="px-9 py-3" onClick={handleCall} />
        </div>

        <div className='lg:hidden justify-around'>
          <div onClick={() => setNavigation(true)} className='cursor-pointer'>
            <FaBars size={30} />
          </div>
        </div>
      </div>

      <div className={navigation ? 'lg:hidden fixed left-0 top-0 w-full h-full bg-gray-900/70 backdrop-blur z-30' : ""}>
        <div className={navigation ? 'fixed left-0 top-0 w-4/5 h-full bg-gray-900 text-white p-10 ease-in duration-300' : "fixed top-0 left-[-100%] h-full p-10 ease-in duration-300"}>
          <div>
            <div className='flex w-full items-center justify-between'>
              <a href='/'><p onClick={() => setNavigation(false)} className='text-xl font-bold capitalize cursor-pointer'>Shivaay Planning Services</p></a>

              <div onClick={() => setNavigation(false)} className='p-3 cursor-pointer'>
                <FaTimes fontSize={30} />

              </div>
            </div>
          </div>

          <div className='mt-12 flex flex-col h-fit gap-12'>
            <ul>
              {
                links.map(({ id, link, title }) => (
                  <a href={'/' + link}><li key={id} className='capitalize text-xl tracking-wider p-3 cursor-pointer hover:text-lightColor duration-200'>{title}</li></a>
                ))
              }

            </ul>
            <GreenBtn className="px-0 py-3 capitalize" title="Call Us" onClick={handleCall} />
          </div>

        </div>

      </div>

    </div>

  )
}

export default Navbar
