/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react'
import GreenBtn from "./GreenBtn";
import axios from 'axios';
import QuoteGenerate from './QuoteGenerate';
import ThankYou from './ThankYou';
import emailjs from '@emailjs/browser';
import { v4 as uuidv4 } from 'uuid';


const ExtendedQuoteForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const projectTypes = [
        {
            id: 1,
            name: "Single Storey Rear Extension"
        },
        {
            id: 2,
            name: "Single Storey Side Extension"
        },
        {
            id: 3,
            name: "Single Storey Wrap Around Extension"
        },
        {
            id: 4,
            name: "Double Storey Extension"
        },
        {
            id: 5,
            name: "Garage Conversion"
        },
        {
            id: 6,
            name: "Loft Conversion"
        },
        {
            id: 7,
            name: "Porch Addition"
        },
        {
            id: 8,
            name: "Outbuilding"
        },
        {
            id: 9,
            name: "Garage Building"
        },
        {
            id: 10,
            name: "Let's Discuss My Requirements"
        },

    ]

    const serviceType = [
        {
            id: 1,
            name: "Drawings for Planning Permission Only"
        },
        {
            id: 2,
            name: "Drawings for Building Regulations Only"
        },
        {
            id: 3,
            name: "Planning Permission/​Building Regulations Package"
        },
        {
            id: 4,
            name: "Let's Discuss My Requirements"
        },
    ]
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};
        Object.keys(formData).forEach((fieldName) => {
          const error = validateField(fieldName, formData[fieldName]);
          if (error) {
            newErrors[fieldName] = error;
          }
        });
      
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
          const uniqueReference = uuidv4(); // Generate a unique reference number
          const templateParams = {
            name: formData.name,
            email: formData.email,
            address: formData.address,
            postcode: formData.postcode,
            phone: formData.phone,
            comments: formData.comment,
            planningType: planningType,
            serviceType: serviceState,
            time: new Date().toLocaleString(),
            referenceNumber: uniqueReference, // Include the reference number in templateParams
          };
      
          setIsLoading(true);
          setShowChildComponent(false);
      
          try {
            // Send the data using emailjs
            const result = await emailjs.send(
              'service_rzuilqi',
              'template_xad5led',
              templateParams,
              'X62OXcAsJZh3dA0WC'
            );
      
            if (result.text === 'OK') {
              console.log('Email sent successfully');
            } else {
              console.error('Failed to send email:', result.text);
            }
      
            setIsLoading(false);
            setIsSubmitted(true);
            localStorage.setItem('isSubmitted', 'true');
            setShowChildComponent(true);
          } catch (error) {
            console.error('Error sending email:', error);
            setIsLoading(false);
          }
        }
      };
      
      useEffect(() => {
        // Check if the formSubmitted state is true in local storage
        const isSubmitted = localStorage.getItem('isSubmitted') === 'true';
        if (isSubmitted) {
            setIsSubmitted(true);
        }
      }, []);
    
      

    const validateField = (fieldName, value) => {
        let errorMessage = "";


        switch (fieldName) {
            case "name":
                if (value.trim() === "") {
                    errorMessage = "Name is required.";
                }
                break;
            case "email":
                if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
                    errorMessage = "Invalid email address.";
                }
                break;
            case "address":
                if (value.trim() === "") {
                    errorMessage = "Address is required.";
                }
                break;
            case "postcode":
                if (value.trim() === "") {
                    errorMessage = "Postcode is required.";
                }
                break;
            case "phone":
                if (!value.match(/^\d{11}$/)) {
                    errorMessage = "Phone number must be 11 digits.";
                }

                break;
            default:
                break;
        }
        return errorMessage;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));

    };


    const handlePlanningCheckbox = (event) => {
        setPlanning(event.target.name);

    };

    const handleServiceCheckbox = (event) => {
        setService(event.target.name);

    };


    const [formData, setFormData] = useState({
        // Initialize form data fields
        name: '',
        email: '',
        address: '',
        postcode: '',
        phone: '',
        comment: '',
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        address: "",
        postcode: "",
        phone: "",
    });



    const [isLoading, setIsLoading] = useState(false);
    const [showChildComponent, setShowChildComponent] = useState(false);

    


    const [planningType, setPlanning] = useState("");
    const [serviceState, setService] = useState("");

    return (
        <div>
            <div>
                <div className="w-full h-full px-6 py-4 overflow-hidden bg-slate-100 sm:max-w-4xl sm:rounded-lg">

                    {!showChildComponent && !isLoading ? (
                            
                        // <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfZwQQp2qkwaQgNDQO0rVMoH347FznQJohNYS79Uhc3xtnTnw/viewform?embedded=true" className='h-[991px] w-[300px] md:w-[640px]' frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                        <form class="form" id="myForm">
                            <h2 className='text-2xl font-bold text-blue pt-5 pb-2'>Let's get started 👋</h2>
                            <p className='mb-10'>It only takes two minutes to get an idea of how much your architectural designs and planning will cost using our handy tool.</p>
                            <div className='flex flex-row justify-between'>
                                <div>
                                    <label
                                        htmlFor="name"
                                        className="text-black block text-sm font-medium undefined"
                                    >
                                        Full Name<span className='text-red-500'>*</span>
                                    </label>
                                    <div className="flex flex-col items-start">
                                        <input
                                            value={formData.name}
                                            type="text"
                                            name="name"
                                            required
                                            placeholder='Enter your full name'
                                            onChange={handleChange}

                                            className="text-black block w-full mt-2 p-2 md:w-[350px] border border-gray-200 rounded-xl shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        />
                                        {errors.name && <span className="text-red-500">{errors.name}</span>}
                                    </div>
                                </div>
                                <div className="">
                                    <label
                                        htmlFor="email"
                                        className="text-black block text-sm font-medium  undefined"
                                    >
                                        Email<span className='text-red-500'>*</span>
                                    </label>
                                    <div className="flex flex-col items-start">
                                        <input
                                            value={formData.email}
                                            type="text"
                                            name="email"
                                            placeholder='Enter your email'
                                            onChange={handleChange}
                                            className="text-black block w-full mt-2 p-2 md:w-[350px] border border-gray-200 rounded-xl shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        />
                                        {errors.email && <span className="text-red-500">{errors.email}</span>}
                                    </div>
                                </div>

                            </div>


                            <div className='flex flex-col justify-between sm:flex-row'>

                                <div className="mt-4">
                                    <label
                                        type="text"
                                        className="text-black block text-sm font-medium undefined"
                                    >
                                        Planning Type<span className='text-red-500'>*</span>
                                    </label>
                                    <label
                                        type="text"
                                        className="text-gray-500 block text-xs undefined"
                                    >
                                        Select only one, if two then select the last option
                                    </label>
                                    {projectTypes.map(({ id, name }) => (
                                        <div key={id} className='flex flex-row items-start my-3'>

                                            <label
                                                for={name}
                                                className="block text-sm text-black undefined"
                                            >
                                                {name}
                                            </label>
                                            <input
                                                type="checkbox"
                                                name={name}
                                                value={name}
                                                onChange={handlePlanningCheckbox}
                                                className="text-black ml-2 block border border-gray-200 mt-1"
                                            />

                                        </div>
                                    ))}


                                </div>

                                <div className="mt-4">
                                    <label
                                        type="text"
                                        className="text-black block text-sm font-medium undefined"
                                    >
                                        What type of service do you require?<span className='text-red-500'>*</span>
                                    </label>
                                    <label
                                        type="text"
                                        className="text-gray-500 block text-xs undefined"
                                    >
                                        Select only one, if two then select the last option
                                    </label>
                                    {serviceType.map(({ id, name }) => (
                                        <div key={id} className='flex flex-row items-start my-3'>

                                            <label
                                                for={name}
                                                className="block text-sm text-black undefined"
                                            >
                                                {name}
                                            </label>
                                            <input
                                                type="checkbox"
                                                value={serviceState}
                                                onChange={handleServiceCheckbox}
                                                name={name}
                                                className="text-black ml-2 block border border-gray-200 mt-1"
                                            />

                                        </div>
                                    ))}


                                </div>

                            </div>
                            <div className="mt-3">
                                <label
                                    htmlFor="address"
                                    className="text-black block text-sm font-medium  undefined"
                                >
                                    Address
                                </label>
                                <div className="flex flex-col items-start">
                                    <input
                                        value={formData.address}
                                        type="text"
                                        name="address"
                                        placeholder='Enter your address'
                                        onChange={handleChange}
                                        className="text-black block w-full mt-2 p-2 border border-gray-200 rounded-xl shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    />
                                    {errors.address && <span className="text-red-500">{errors.address}</span>}
                                </div>
                            </div>

                            <div className='flex flex-row justify-between mt-3'>
                                <div>
                                    <label
                                        htmlFor="postcode"
                                        className="text-black block text-sm font-medium undefined"
                                    >
                                        Postcode<span className='text-red-500'>*</span>
                                    </label>
                                    <div className="flex flex-col items-start">
                                        <input
                                            type="text"
                                            name="postcode"
                                            placeholder='Enter your postcode'
                                            value={formData.postcode}
                                            onChange={handleChange}

                                            className="text-black block w-full mt-2 p-2 md:w-[350px] border border-gray-200 rounded-xl shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        />
                                        {errors.postcode && <span className="text-red-500">{errors.postcode}</span>}
                                        
                                    </div>
                                </div>
                                <div className="">
                                    <label
                                        htmlFor="phone"
                                        className="text-black block text-sm font-medium  undefined"
                                    >
                                        Phone<span className='text-red-500'>*</span>
                                    </label>
                                    <div className="flex flex-col items-start">
                                        <input
                                            value={formData.phone}
                                            type="text"
                                            name="phone"
                                            placeholder='Enter your phone'
                                            onChange={handleChange}
                                            className="text-black block w-full mt-2 p-2 md:w-[350px] border border-gray-200 rounded-xl shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        />
                                        {errors.phone && <span className="text-red-500">{errors.phone}</span>}
                                    </div>
                                </div>



                            </div>



                            <div className="mt-4">
                                <label
                                    type="comments"
                                    className="block text-sm font-medium text-black undefined"
                                >
                                    Comments
                                </label>
                                <div className="flex flex-col items-start">
                                    <textarea
                                        value={formData.comment}
                                        type="comments"
                                        onChange={handleChange}
                                        rows="3" cols="5"
                                        name="comment"
                                        placeholder='Anything else you may want us to know'
                                        className="text-black block w-full mt-2 p-2 border border-gray-200 rounded-xl shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="flex items-center justify-center mt-4">
                                <GreenBtn title="Submit" className="px-6 py-2" onClick={handleSubmit} />
                            </div>
                        </form>

                    ) : isLoading && !showChildComponent ? (
                        <div class="flex flex-col justify-center items-center h-fit">

                            <div class="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-purple-400 via-blue-500 to-red-400 ">
                                <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-white rounded-full border-2 border-white"></div>
                            </div>

                            <h1 className='mt-5 font-medium animate-pulse'>Retrieving your personalised quote<span className='animate-pulse'>...</span></h1>
                        </div>
                    ) : (
                        <ThankYou planningType={planningType} serviceType={serviceState} formData={formData}/>

                    )}

                </div>
            </div>
        </div>

    )
}

export default ExtendedQuoteForm
