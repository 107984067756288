import React from 'react'
import Hero from '../components/Hero'
import Testimonals from '../components/Testimonals'
import WhyUs from '../components/WhyUs'
import ReadyBanner from '../components/ReadyBanner'
import ReviewTest from '../components/ReviewTest'


// export default function Main() {
//   return (
//     <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0, transition: {duration:0.1}}}>
//       <Hero/>
//       <WhyUs/>
//       <Testimonals/>
//       <ReviewTest/>
//       <ReadyBanner/>

//     </motion.div>
//   )
// }

import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet-async'
import GoogleAnalytics from '../components/GoogleAnalytics'


const AnimatedSection = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0.5 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 30 }}
      animate={controls}
      transition={{ duration: 1.5 }}
    >
      {children}
    </motion.div>
  );
};

const Main = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.1 } }} className='bg-testColor'>
      <Helmet>
        <script type="application/ld+json">
        {JSON.stringify({
            "@context": "http://schema.org",
          "@type": "LocalBusiness",
          "name": "Shivaay Planning Services",
          "image": "https://shivaayplanning.com/static/media/logosv3.b89a5a821627f493f2612803f23e71fd.svg",
          "telephone": "07410377527",
          "email": "info@shivaayplanning.com"
          })}
        </script>
        <title>Shivaay Planning Services</title>
        <meta
          name="description"
          content="Shivaay Planning Services specialise in planning, building control, interior design, and the construction of residential projects."
        />
        <meta name="keywords" content="architectural, shivaay, planning, architecture, southall, london, near, services" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Shivaay Planning Services" />
        <meta property="og:description" content="Shivaay Planning Services specialise in planning, building control, interior design, and the construction of residential projects. Get to know more by geting a quote Now!" />
        <meta property="og:url" content="www.shivaayplanning.com" />
        <meta name="twitter:title" content="Shivaay Planning Services" />
        <meta name="twitter:description" content="Shivaay Planning Services specialise in planning, building control, interior design, and the construction of residential projects. Get to know more by geting a quote Now!" />
        <link rel="canonical" href='/' />
      </Helmet>
      <GoogleAnalytics />
      <AnimatedSection>
        <Hero />
      </AnimatedSection>
      <AnimatedSection>
        <WhyUs />
      </AnimatedSection>
      <AnimatedSection>
        <Testimonals />
      </AnimatedSection>
      <AnimatedSection>
        <ReviewTest />
      </AnimatedSection>
      <AnimatedSection>
        <ReadyBanner />
      </AnimatedSection>
    </motion.div>
  );
};

export default Main;
