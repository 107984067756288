import React, { useEffect, useState } from 'react'
import ExperienceWidget from '../components/ExperienceWidget'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

export default function AboutUs() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 746);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 746);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (

    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} name="aboutus" className='w-full h-fit bg-testColor'>
      <div className="flex flex-col justify-center items-start max-w-screen-xl mx-auto h-full px-3 py-12">
        <Helmet>
          <title>Shivaay Planning | About Us</title>
          <meta
            name="description"
            content="Discover excellence in architectural innovation with Shivaay Planning Services. Explore our services and request a quote today to embark on your visionary journey."
          />
          <meta property="og:type" content="website" />
        <meta property="og:title" content="Shivaay Planning | About Us" />
        <meta property="og:description" content="Discover excellence in architectural innovation with Shivaay Planning Services. Explore our services and request a quote today to embark on your visionary journey." />
        <meta property="og:url" content="www.shivaayplanning.com" />
        <meta name="twitter:title" content="Shivaay Planning Services | About Us" />
        <meta name="twitter:description" content="Discover excellence in architectural innovation with Shivaay Planning Services. Explore our services and request a quote today to embark on your visionary journey." />
          <link rel="canonical" href='/about-us'/>
        </Helmet>
        <motion.h1 className='text-3xl sm:text-5xl font-bold text-blue mt-24 w-full'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          About Us
        </motion.h1>
        <motion.h2 className='text-lg py-5'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          We undertake hundreds of projects each and every year. Our ever-growing experience has enabled us to develop our service to focus on delivering what really matters - our approach works and has become very successful.
        </motion.h2>
        {isDesktop ? (
          <motion.div className='border border-lightColor flex flex-row mx-auto mb-16'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <div className='w-[400px] h-[400px]'>
              {/* Your content here */}
            </div>
            <div className='w-[600px] h-[400px] -ml-96 mt-5 -mr-5 bg-white shadow-xl'>
              <motion.h3 className='text-lg font-bold p-8'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.6 }}
              >
                Shivaay Planning Services are the best!
              </motion.h3>
              <motion.h4 className='text-md px-8'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.8 }}
              >
                At Shivaay Planning Services, our expertise in architectural work and planning permissions has made us the premier choice since 2016.
                With a vast portfolio of over 100 satisfied customers across London and the UK, our success stems from our unwavering commitment to putting the customer first.
                No other planning service rivals the excellence we bring to every project. <br /> <br /> Our dedication to innovative solutions sets us apart, ensuring your vision becomes reality.

              </motion.h4>
            </div>
          </motion.div>
        ) : (
          <motion.div className='bg-white rounded-md'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <motion.h2 className='text-lg font-bold p-8'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              Shivaay Planning Services are the best!
            </motion.h2>
            <motion.p className='text-md px-8 pb-8'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.8 }}
            >
              At Shivaay Planning Services, our expertise in architectural work and planning permissions has made us the premier choice since 2016.
              With a vast portfolio of over 100 satisfied customers across London and the UK, our success stems from our unwavering commitment to putting the customer first.
              No other planning service rivals the excellence we bring to every project. <br /> <br /> Our dedication to innovative solutions sets us apart, ensuring your vision becomes reality.
            </motion.p>
          </motion.div>
        )}
      </div>
      <ExperienceWidget />
    </motion.div>
  )
}
