import React, { useState, useEffect } from 'react';

export default function AnimatedCounter({ target, duration }) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    let startTime;
    let handle;

    function updateCounter(timestamp) {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const increment = (target / duration) * progress;

      if (current + increment < target) {
        setCurrent(current + increment);
        handle = requestAnimationFrame(updateCounter);
      } else {
        setCurrent(target);
      }
    }

    handle = requestAnimationFrame(updateCounter);

    return () => {
      cancelAnimationFrame(handle);
    };
  }, [target, duration, current]);

  return <span className='font-bold'>{Math.round(current)}</span>;
}
