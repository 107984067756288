import React, { useEffect, useState } from 'react'
import Carousel from '../components/Carousel'
import GreenBtn from '../components/GreenBtn'
import before1 from '../assets/portfolio/hayes/before.jpeg'
import southhaye from '../assets/portfolio/hayes/maxfootprint.mp4'
import num2 from '../assets/portfolio/2.mp4'
import num3 from '../assets/portfolio/3_.mp4'
import num4 from '../assets/portfolio/4.mp4'
import num5 from '../assets/portfolio/5.mp4'
import num6 from '../assets/portfolio/6.mp4'
import num7 from '../assets/portfolio/7.mp4'
import num8 from '../assets/portfolio/8_.mp4'
import num9 from '../assets/portfolio/9.mp4'
import { motion } from 'framer-motion'
import AnimatedCounter from '../components/AnimatedCounter'
import { Helmet } from 'react-helmet-async'

const Portfolio = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const pics = [
        {
            id: 1,
            pic: [southhaye, before1],
            service: "Maximising Footprint",
            address: "Redbridge",
        },
        {
            id: 2,
            pic: [num2],
            service: "Loft Conversion",
            address: "Merton Council",
        },
        {
            id: 3,
            pic: [num3],
            service: "New Build Flats",
            address: "Newham",
        },
        {
            id: 4,
            pic: [num4],
            service: "Roof Extension",
            address: "Brent Council",
        },
        {
            id: 5,
            pic: [num5],
            service: "L-Shape Dormer",
            address: "Merton Council",
        },
        {
            id: 6,
            pic: [num6],
            service: "Double Storey Rear & Side",
            address: "Harrow Council",
        },
        {
            id: 7,
            pic: [num7],
            service: "Single Side & Rear",
            address: "Hillingdon Council",
        },
        {
            id: 8,
            pic: [num8],
            service: "Flat Extension",
            address: "Brent Council",
        },
        {
            id: 9,
            pic: [num9],
            service: "CGI",
            address: "London",
        },

    ]

    const [displayedPics, setDisplayedPics] = useState(pics.slice(0, 6));
    const [showMore, setShowMore] = useState(true);
    const [pending, setPending] = useState(false);

    const handleLoadMore = () => {
        if (!pending) {
            setPending(true);
            setTimeout(() => {
                if (showMore) {
                    setDisplayedPics(pics);
                } else {
                    setDisplayedPics(pics.slice(0, 6));
                }
                setShowMore(!showMore);
                setPending(false);
            }, 2000);
        }

    };

    function ProjectCounter() {
        const target = 557;
        const duration = 2000; // Duration in milliseconds (adjust as needed)

        return (
            <div className='mt-5 p-7 text-lg bg-white  rounded-xl text-black'>
                <h2>
                    Since 2016 completed <span className='font-bold'>
                        <AnimatedCounter target={target} duration={duration} />
                    </span> projects including residential and commercial.
                </h2>
            </div>
        );
    }




    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} name="portfolio" className='w-full h-fit bg-testColor'>
            <Helmet>
                <title>Shivaay Planning | Portfolio</title>
                <meta
                    name="description"
                    content="Exploring Architectural Mastery: Shivaay Planning Services Portfolio. Immerse yourself in a visual journey through our diverse range of residential projects."
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Shivaay Planning | Portfolio" />
                <meta property="og:description" content="Exploring Architectural Mastery: Shivaay Planning Services Portfolio. Immerse yourself in a visual journey through our diverse range of residential projects." />
                <meta property="og:url" content="www.shivaayplanning.com" />
                <meta name="twitter:title" content="Shivaay Planning | Portfolio" />
                <meta name="twitter:description" content="Exploring Architectural Mastery: Shivaay Planning Services Portfolio. Immerse yourself in a visual journey through our diverse range of residential projects." />
                <link rel="canonical" href='/portfolio' />
            </Helmet>
            <div className='flex flex-col items-start justify-center max-w-screen-xl mx-auto h-full px-3 pt-12 pb-24'>
                <motion.h1 className='text-3xl sm:text-5xl font-bold text-blue mt-24'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6 }}
                >
                    Portfolio
                </motion.h1>
                <motion.h2 className='text-md pt-5'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    Take a look at the completed projects we have done.
                </motion.h2>
                <ProjectCounter />

                <div className='grid grid-cols-1 gap-4 m-auto px-4 pt-12 sm:pt-20 sm:grid-cols-2 md:grid-cols-3 text-black'>
                    {displayedPics.map(({ id, pic, service, address }) => (
                        <motion.div key={id} className=''
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.4 }}
                        >
                            <div>
                                <Carousel pics={pic} title={service} address={address} />
                            </div>
                            <motion.h3 className='text-blue text-md'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.6, delay: 0.5 }}
                            >
                                {service}
                            </motion.h3>
                            <motion.h4 className='text-sm'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.6, delay: 0.6 }}
                            >
                                {address}
                            </motion.h4>
                        </motion.div>
                    ))}
                </div>
                <div class="flex items-center justify-center">

                </div>

                {pending ? (
                    <div class="w-4/12 sm:w-2/12 mx-auto mt-10">
                        <button type="button"
                            class="inline-flex items-center justify-center py-2 w-full border bg-lightColor text-darkColor font-bold border-lightColor cursor-not-allowed hover:bg-transparent hover:text-lightColor rounded-md duration-300 ease-out"
                            disabled=""
                        >
                            <motion.svg class="w-5 h-5 mr-3 -ml-1 text-blue animate-spin"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.6, delay: 0.7 }}
                            >
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                </path>
                            </motion.svg>
                            <span className='animate-pulse'>Loading...</span>
                        </button>
                    </div>
                ) : (
                    <GreenBtn
                        className="w-4/12 sm:w-2/12 mx-auto py-2 mt-10"
                        title={pending ? "Pending..." : showMore ? "Load More" : "Show Less"}
                        onClick={handleLoadMore}
                        disabled={pending}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.6, delay: 0.8 }}
                    />
                )}

            </div>
        </motion.div>
    )
}

export default Portfolio
