import React from 'react'
import GreenBtn from './GreenBtn'
import building from '../assets/building.png'
import { useNavigate } from 'react-router-dom';

export default function ReadyBanner() {
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/get-a-quote`;
        navigate(path);
    }
    return (
        <div name="ready" className='w-full h-fit bg-testColor'>
            <div className=" w-full mx-auto h-full px-5 py-10 md:p-40 md:py-40 bg-blue">
                <div className='grid grid-cols-1 lg:grid-cols-2 justify-center '>
                    <div className=' items-start max-w-xl mx-auto'>
                        <h2 className='text-4xl md:text-6xl font-bold text-white mt-4 w-full max-w-screen-sm text-center sm:text-start'>Ready to make your dream come to life & save money?</h2>
                        <p className='mt-10 text-white text-center sm:text-start sm:mr-20 '>Join the portfolio of our satisfied customers today and get your free quote now!</p>
                        <div className='flex flex-row sm:max-w-sm w-full mt-10 justify-center sm:justify-start'>
                            <GreenBtn title="Get a Quote now →" className="text-lg capitalize py-3 px-6 lg:w-8/12 sm:w-8/12 mb-16 " onClick={routeChange}/>
                        </div>

                    </div>
                    <div className='-mt-8 max-w-lg  mx-auto'>
                        <img src={building} alt="building sketch" />
                    </div>

                </div>



            </div>
        </div>
    )
}
