import React, { useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather';


const Carousel = ({ pics, title, address }) => {
    const [curr, setCurr] = useState(0);

    const prev = () => setCurr((curr) => (curr === 0 ? pics.length - 1 : curr - 1));
    const next = () => setCurr((curr) => (curr === pics.length - 1 ? 0 : curr + 1));




    return (
        <div className='overflow-hidden relative group '>
            <div className='flex transition-transform ease-out duration-500' style={{ transform: `translateX(-${curr * 100}%)` }}>
                {pics.map((pic, index) => (
                    <React.Fragment key={index}>
                        {index === 0 ? (
                            <React.Fragment>
                                <video id='carousel-video' controls={false} autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true">
                                    <source src={pic} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                 <div className='absolute inset-0 bg-blue bg-opacity-50 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 ease-out duration-200'>
                                     <h3 className='text-white text-xl font-bold mb-2'>{title}</h3>
                                     <h4 className='text-white text-sm'>{address}</h4>
                                </div> 
                            </React.Fragment>


                        ) : (
                            <img src={pic} alt={`Pic ${index + 1}`} />
                        )}

                        
                    </React.Fragment>
                ))}
            </div>
            <div className='absolute inset-0 flex items-center justify-between p-4'>
                <button className='bg-lightColor opacity-80 text-blue rounded-full p-2' onClick={prev}><ChevronLeft size={15} /></button>
                <button className='bg-lightColor opacity-80 text-blue rounded-full p-2' onClick={next}><ChevronRight size={15} /></button>
            </div>

            <div className='absolute bottom-4 right-0 left-0'>
                <div className='flex items-center justify-center gap-2'>
                    {pics.map((_, i) => (
                        <div key={i} className={`transition-all w-2 h-2 bg-white rounded-full ${curr === i ? "p-1" : "bg-opacity-50"}`} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Carousel;






