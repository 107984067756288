import React from 'react'
import ExtendedQuoteForm from '../components/ExtendedQuoteForm'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async'

export default function QuotePage() {
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} name="quote" className='w-full h-fit bg-testColor'>
      <Helmet>
          <title>Shivaay Planning | Get a Quote</title>
          <meta
            name="description"
            content="Transform Your Vision into Reality: Request a Quote from Shivaay Planning Services. Share your ideas, and our expert team will craft them into tangible designs."
          />
          <meta property="og:type" content="website" />
        <meta property="og:title" content="Shivaay Planning | Get a Quote" />
        <meta property="og:description" content="Transform Your Vision into Reality: Request a Quote from Shivaay Planning Services. Share your ideas, and our expert team will craft them into tangible designs." />
        <meta property="og:url" content="www.shivaayplanning.com" />
        <meta name="twitter:title" content="Shivaay Planning | Get a Quote" />
        <meta name="twitter:description" content="Transform Your Vision into Reality: Request a Quote from Shivaay Planning Services. Share your ideas, and our expert team will craft them into tangible designs." />
          <link rel="canonical" href='/get-a-quote'/>
        </Helmet>
      <div className='flex flex-col justify-center items-center max-w-screen-xl mx-auto h-full px-3 py-12'>
        <h1 className='text-3xl sm:text-5xl font-bold text-blue mt-24 w-full'>Get a Quote</h1>
        <h2 className='text-l pt-5'>To start the booking process we will need to collect some information from you. Once you have submitted your 
        form we shall assess the information and contact you to discuss your requirements in greater detail.</h2>
        <div className='my-5'>
            <ExtendedQuoteForm/>

        </div>
        

      </div>
    </motion.div>
  )
}
