import React from 'react'
import GreenBtn from './BlueBtn'
import { useNavigate } from "react-router-dom";

const LatestWork = () => {

    const pics = [
        {
            id: 1,
            service: "Drop Kerb Application",
            img: require("../assets/latestwork/dropkerb.jpeg")

        },
        {
            id: 2,
            service: "Front Porch Extension",
            img: require("../assets/latestwork/frontporch.jpeg")

        },
        {
            id: 3,
            service: "Single Storey Side/Rear Extension",
            img: require("../assets/latestwork/singlestoreyside.jpeg")

        },
        {
            id: 4,
            service: "Double Storey Side/Rear Extension",
            img: require("../assets/latestwork/doublestoeryside.jpeg")

        },
        {
            id: 5,
            service: "Loft Conversion",
            img: require("../assets/latestwork/loftconv.jpeg")

        },
        {
            id: 6,
            service: "Flat Conversion",
            img: require("../assets/latestwork/flatconv.jpeg")

        },
    ]

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/services`;
        navigate(path);
    }



    return (
        <div name="testimonals" className='w-full h-fit  rounded-3xl py-10 -mt-10'>
            <div className='flex flex-col items-center max-w-screen-lg xl:max-w-screen-xl  mx-auto h-full px-3 my-auto '>
                <h4 className='w-full text-2xl font-bold'>Services We Offer</h4>
                <div className='relative flex overflow-x-hidden w-full '>


                    <div className='grid lg:grid-cols-7 gap-x-96 gap-y-16 px-4 py-10 text-black animate-marquee'>
                        {pics.map(({ id, service, img }) => (
                            <div key={id} className='shadow-lg h-[150px] w-[350px] bg-testWhite  group relative inline-block overflow-hidden focus:outline-none focus:ring'>
                               
                                <div className='w-fit flex flex-row '>
                                    <p className='px-5 my-auto text-md font-bold text-center text-sky-900'>{service}</p>
                                    <img src={img} alt='shivaay planning services' className='h-[150px] w-auto' />
                                    <span class="ease absolute left-0 top-0 h-0 w-0 border-t-2 border-blue transition-all duration-200 group-hover:w-full"></span>
                                    <span class="ease absolute right-0 top-0 h-0 w-0 border-r-2 border-blue transition-all duration-200 group-hover:h-full"></span>
                                    <span class="ease absolute bottom-0 right-0 h-0 w-0 border-b-2 border-blue transition-all duration-200 group-hover:w-full"></span>
                                    <span class="ease absolute bottom-0 left-0 h-0 w-0 border-l-2 border-blue transition-all duration-200 group-hover:h-full"></span>

                                </div>
                            </div>
                        ))}

                    </div>
                    <div className='absolute grid lg:grid-cols-7 gap-x-96 gap-y-16 px-4 py-10 text-black animate-marquee2'>
                        {pics.map(({ id, service, img }) => (
                            <div key={id} className='shadow-lg h-[150px] w-[350px] bg-testWhite  group relative inline-block overflow-hidden hover:text-blue focus:outline-none focus:ring active:text-white'>
                               
                                <div className='w-fit flex flex-row '>
                                    <p className='px-5 my-auto text-md font-bold text-center text-sky-900'>{service}</p>
                                    <img src={img} alt='shivaay planning services' className='h-[150px] w-auto' />
                                    <span class="ease absolute left-0 top-0 h-0 w-0 border-t-2 border-blue transition-all duration-200 group-hover:w-full"></span>
                                    <span class="ease absolute right-0 top-0 h-0 w-0 border-r-2 border-blue transition-all duration-200 group-hover:h-full"></span>
                                    <span class="ease absolute bottom-0 right-0 h-0 w-0 border-b-2 border-blue transition-all duration-200 group-hover:w-full"></span>
                                    <span class="ease absolute bottom-0 left-0 h-0 w-0 border-l-2 border-blue transition-all duration-200 group-hover:h-full"></span>

                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <GreenBtn title="See all →" className="px-6 py-4 " onClick={routeChange} />
            </div>
        </div>
    )
}

export default LatestWork
