import React from 'react'
import image from '../assets/how-we-work/constuction.png'
import preplaning from '../assets/how-we-work/preplanning.jpg'
import Testimonals from '../components/Testimonals'
import scheme from '../assets/how-we-work/scheme.jpg'
import drawing from '../assets/how-we-work/planningdrwaing.jpg'
import process from '../assets/how-we-work/process.jpg'
import packages from '../assets/how-we-work/package.jpg'
import buildingnotice from '../assets/how-we-work/buildingnotice.jpg'
import advice from '../assets/how-we-work/advice.jpg'
import specialist from '../assets/how-we-work/specialist.jpg'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async'

export default function HowWeWork() {
  const services = [
    {
      id: 1,
      title: 'Pre-Project Planning/Feasibility',
      subTitle: "During this stage, we engage in a phone call with our client via the details given to us using our free quote tool, to discuss their vision. We assess the site's conditions, regulations, and potential restrictions to determine if planning permission is required or if it falls under permitted developments. By carefully evaluating various options, we provide our client with the best course of action tailored to their needs.",
      imagelink: preplaning,
    },
    {
      id: 2,
      title: 'The Site Visit',
      subTitle: "During this stage, our experts thoroughly inspect the property, including the location of manholes and utility connections. We also assess neighboring properties to understand their relationship with the proposed project, considering factors like privacy, sunlight, and potential impacts. This comprehensive evaluation provides essential data for a well-informed and compliant planning application.",
      imagelink: image,
    },
    {
      id: 3,
      title: 'Development Of A Suitable Proposed Scheme',
      subTitle: "In this stage, our architecture and planning team crafts a design that aligns with the client's vision, while carefully considering London planning guidance, permitted development rights, and relevant Councils' Supplementary Planning Guidance (SPGs) and Supplementary Planning Documents (SPDs). This ensures compliance with local regulations and fosters a sustainable, innovative, and socially responsible project outcome in London's urban context.",
      imagelink: scheme,
    },
    {
      id: 4,
      title: 'High Quality and Easily Understandable Planning Drawings',
      subTitle: "This stage plays a crucial role in our architectural services. These visuals give clear communication, enabling councils, planning officers, and clients to understand and assess the proposed project's compliance with planning regulations effectively. This transparency fosters a collaborative approach and ensures a smooth planning process.",
      imagelink: drawing,
    },
    {
      id: 5,
      title: 'The Planning Permission Process',
      subTitle: "This process depends on the proposal type. For projects falling under permitted development, the timeline is typically around 8 weeks for approval. However, full planning applications, which involve more complex projects, may require up to 12 weeks for assessment and decision-making. Our dedicated team ensures that all necessary documentation and compliance are met to expedite the process and achieve a successful outcome for our clients.",
      imagelink: process,
    },
    {
      id: 6,
      title: 'High Quality and Well Detailed Building Regulations Packages',
      subTitle: "At our architecture firm, we prioritise delivering high-quality and well-detailed building regulations packages. These comprehensive documents include thorough technical specifications, construction drawings, and compliance details. By addressing all relevant codes and standards, we ensure our projects smoothly navigate the approval process and successfully transition from design to construction phase. This attention to detail fosters confidence in our clients and enhances the efficiency and reliability of the building process this also helps obtain the builders quote.",
      imagelink: packages,
    },
    {
      id: 7,
      title: 'Building Control Notice ',
      subTitle: "This is a formal application submitted to the local authority before commencing construction work. It includes detailed plans, specifications, and structural calculations, demonstrating how the project will comply with relevant building regulations and standards. Once approved, the notice allows for regular inspections at various stages of construction to ensure safety, accessibility, and quality are maintained throughout the building process. This rigorous oversight ensures that the completed structure meets all necessary requirements and provides a safe environment for occupants.",
      imagelink: buildingnotice,
    },
    {
      id: 8,
      title: 'Specialist Input',
      subTitle: "In this stage we collaborate with experts in various fields, such as structural engineering, environmental sustainability, acoustics, and accessibility, among others. Their expertise enriches the project with innovative solutions, technical accuracy, and adherence to specific regulations and standards. By integrating their knowledge, we can address complex challenges and optimise design decisions, leading to a more efficient and successful outcome for our clients.",
      imagelink: specialist,
    },
    {
      id: 9,
      title: 'Aftercare, Support And Advice',
      subTitle: "Aftercare, Support, and Advice are integral parts of our architectural services. We provide ongoing assistance to clients, ensuring seamless project execution and addressing any post-completion concerns. Our commitment to exceptional support guarantees client satisfaction and long-term project success.",
      imagelink: advice,
    },
  ]


  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} name="howwework" className='w-full h-fit bg-testColor'>
      <div className='flex flex-col justify-center items-start max-w-screen-xl mx-auto h-full px-3 py-12'>
      <Helmet>
          <title>Shivaay Planning | How We Work</title>
          <meta
            name="description"
            content="Unveiling Our Process at Shivaay Planning Services: Navigate the realm of architectural finesse through our meticulous approach. From conceptualization to realisation."
          />
          <meta property="og:type" content="website" />
        <meta property="og:title" content="Shivaay Planning Services | How We Work" />
        <meta property="og:description" content="Unveiling Our Process at Shivaay Planning Services: Navigate the realm of architectural finesse through our meticulous approach. From conceptualization to realisation." />
        <meta property="og:url" content="www.shivaayplanning.com" />
        <meta name="twitter:title" content="Shivaay Planning Services | How We Work" />
        <meta name="twitter:description" content="Unveiling Our Process at Shivaay Planning Services: Navigate the realm of architectural finesse through our meticulous approach. From conceptualization to realisation." />
          <link rel="canonical" href='/how-we-work'/>
        </Helmet>
        <motion.h1 className='text-3xl sm:text-5xl font-bold text-blue mt-24 w-full'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          How We Work
        </motion.h1>
        <motion.h2 className='text-lg py-5'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          We undertake hundreds of projects each and every year. Our ever-growing experience has enabled us to develop our service to focus on delivering what really matters - our approach works and has become very successful.
        </motion.h2>
        <div className='w-full [&>*:nth-child(even)]:flex-col sm:[&>*:nth-child(even)]:flex-row-reverse'>
          {services.map(({ id, title, subTitle, imagelink }) => (
            <motion.div key={id} className='flex flex-col sm:flex-row m-auto mt-14 justify-center'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <motion.div className='w-fit sm:w-[500px] shadow-xl p-8 bg-white'
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.5 }}
              >
                <motion.div className='bg-yellow-400 rounded-full w-12 h-12 m-auto -mt-14 p-3'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.6 }}
                >
                  <p className='text-center'>{id}</p>
                </motion.div>
                <motion.h2 className='text-xl font-semibold py-6 text-start'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.7 }}
                >
                  {title}
                </motion.h2>
                <motion.h3 className='text-sm text-start'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.8 }}
                >
                  {subTitle}
                </motion.h3>
              </motion.div>
              <motion.div className='shadow-md'
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.9 }}
              >
                <img src={imagelink} className='h-[300px]' alt={title}></img>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
      <Testimonals />
    </motion.div>

  )
}
