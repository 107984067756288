import React from 'react'
import GreenBtn from "./GreenBtn";



const QuoteForm = () => {
  return (
    <div>
            <div>
                <div className="w-full h-full px-6 py-4 overflow-hidden bg-white shadow-md sm:max-w-xl sm:rounded-lg">
                    <form>
                        <div>
                            <label
                                htmlFor="name"
                                className="text-black block text-sm font-medium undefined"
                            >
                                Full Name<span className='text-red-500'>*</span>
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    type="text"
                                    name="name"
                                    required
                                    className="text-black block w-full mt-2 p-2 border border-gray-500 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label
                                htmlFor="email"
                                className="text-black block text-sm font-medium  undefined"
                            >
                                Email<span className='text-red-500'>*</span>
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    type="email"
                                    name="email"
                                    className="text-black block w-full mt-2 p-2 border border-gray-500 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label
                                htmlFor="phone"
                                className="text-black block text-sm font-medium  undefined"
                            >
                                Phone<span className='text-red-500'>*</span>
                            </label>
                            <div className="flex flex-col items-start">
                                <input
                                    type="phone"
                                    name="phone"
                                    className="text-black block w-full mt-2 p-2 border border-gray-500 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                            </div>
                        </div>
                        
                        <div className="mt-4">
                            <label
                               type="text"
                                className="block text-sm font-medium text-black undefined"
                            >
                                Why do you want to join us?
                            </label>
                            <div className="flex flex-col items-start">
                                <textarea
                                    type="text"
                                    rows="5" cols="15"
                                    name="password_confirmation"
                                    className="text-black block w-full mt-2 p-2 border border-gray-500 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                ></textarea>
                            </div>
                        </div>
                        <div className="flex items-center justify-center mt-4">
                            <GreenBtn title="Send" className="px-6 py-2"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
   
  )
}

export default QuoteForm
