import React from 'react'

const BlueBtn = ({title, onClick, className}) => {
  return (
    <button className={
        "border bg-sky-100 text-darkColor border-sky-100 hover:bg-transparent hover:text-darkColor rounded-3xl duration-300 ease-out" +
        " " +
        className
      } onClick={onClick}>
        {title}
    </button>

    
  )
}

export default BlueBtn
