import React, { useEffect, useState } from 'react'
import pic1 from '../assets/approvals/1.png'
import pic2 from '../assets/approvals/2.png'
import pic3 from '../assets/approvals/3.png'
import pic4 from '../assets/approvals/4.png'
import BlueBtn from './BlueBtn'
import { useNavigate } from "react-router-dom";

const Testimonals = () => {

    const pics = [
        {
            id: 1,
            pic: pic1,
        },
        {
            id: 2,
            pic: pic2,
        },
        {
            id: 3,
            pic: pic3,
        },
        {
            id: 4,
            pic: pic4,
        },
    ]

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/portfolio`;
        navigate(path);
    }

    const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1050);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <div name="testimonals" className='w-full h-fit bg-slate-100'>
            <div className='flex flex-col items-center justify-center max-w-screen-2xl mx-auto h-full px-3 py-12'>
                <h3 className='w-full text-2xl font-bold'>Our Latest Approvals</h3>
                {isDesktop ? (
                    <div className='overflow-hidden relative flex overflow-x-hidden w-full '>
                    <div className='grid lg:grid-cols-4 grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black animate-marquee '>
                        {pics.map(({ id, pic }) => (
                            <div key={id} className='bg-gray-90'>

                                <div>
                                    <img src={pic} alt="" className='rounded-xl md:w-[290px] w-[150px]' />
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className='absolute grid lg:grid-cols-4 grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black animate-marquee2'>
                        {pics.map(({ id, pic }) => (
                            <div key={id} className='bg-gray-90'>

                                <div>
                                    <img src={pic} alt="" className='rounded-xl md:w-[290px] w-[150px]' />
                                </div>
                            </div>
                        ))}

                    </div>

                </div>

                ): (
                    <div className='grid lg:grid-cols-4 grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-12 sm:pt-20 text-black'>
                        {pics.map(({ id, pic }) => (
                            <div key={id} className='bg-gray-90'>

                                <div>
                                    <img src={pic} alt="" className='rounded-xl md:w-full w-[300px]' />
                                </div>
                            </div>
                        ))}

                    </div>
                )}
                

                <BlueBtn title="View Case Studies ->" className="px-6 py-4 m-10" onClick={routeChange} />
            </div>
        </div>
    )
}

export default Testimonals
